export function getEditorJsInlineToolbar() {
    return [
        'link',
        'bold',
        'italic',
    ];
}

export function getEditorJsTools() {
    const headerComponents = {
        class: window.Header,
        inlineToolbar: true,
    };
    const embedComponents = {
        class: window.Embed,
        inlineToolbar: true,
    };
    const imageComponents = {
        class: window.InlineImage,
        inlineToolbar: true,
        config: {
            embed: {
                display: true,
            },
        },
    };
    const tableComponents = {
        class: window.Table,
        inlineToolbar: true,
        config: {
            rows: 2,
            cols: 6,
            stretched: false,
        },
    };
    const listComponents = {
        class: window.EditorjsList,
        inlineToolbar: true,
        config: {
            defaultStyle: 'unordered',
        },
    };
    const toggleComponents = {
        class: window.ToggleBlock,
        inlineToolbar: true,
    };

    return {
        header: headerComponents,
        embed: embedComponents,
        image: imageComponents,
        table: tableComponents,
        list: listComponents,
        toggle: toggleComponents,
        columns: {
            class: window.editorjsColumns,
            config: {
                EditorJsLibrary: window.EditorJS,
                tools: {},
            },
        },
    };
}

export async function loadEditorJsScripts(appContext) {
    await Promise.all([
        appContext.$loadScript('/js/editorjs-2.30.6.js'),
        appContext.$loadScript('/js/editorjs-embed-2.7.4.js'),
        appContext.$loadScript('/js/editorjs-header-2.8.7.js'),
        appContext.$loadScript('/js/editorjs-inline-image-2.2.2.js'),
        appContext.$loadScript('/js/editorjs-table-2.4.3.js'),
        appContext.$loadScript('/js/editorjs-list-2.0.2.js'),
        appContext.$loadScript('/js/editorjs-toggle-block-0.3.16.js'),
        appContext.$loadScript('/js/editorjs-columns-0.3.2.js'),
    ]);
}
