<template>
  <div class="student-self-learning-mode-list-wrap">
    <V2Wrapper>
      <div class="slmw-inner">
        <div class="v2-base-container">
          <template v-if="this.errorMsg !== null">
            <ErrorMsgBox :contents="this.errorMsg"/>
          </template>
          <template v-else>
            <div class="wi-body-wrap">
              <SelfLearningModeTreeList
                  :on-change-select-range="(e) => this.selectRange = e"
                  :on-change-select-sub-range="(e) => this.selectSubRange = e"
                  :on-change-select-diag-ptn-ids="(e) => this.selectDiagPtnIds = e"
                  :on-change-select-diag-ptn-pre-cnt="(e) => this.selectDiagPtnPreCnt = e"
                  :on-change-select-unit-prb-cnts="(e) => this.selectUnitPrbCnts = e"
              />
              <div class="sec-right-wrap">
                <div class="sec-tab-list">
                  <template v-for="(tabName, index) in this.tabs" :key="index">
                    <div :class="['tl-item', {'active': index === this.selectTabIndex}]"
                         @click="() => this.selectTabIndex = index"
                    >
                      <p>{{ tabName }}</p>
                      <div></div>
                    </div>
                  </template>
                </div>
                <div class="sec-body-wrap">
                  <!--
                  <template v-for="(unitItem, midIndex) in this.settingItems" :key="midIndex">
                    <div class="sb-item">
                      <div class="sbi-title">{{ unitItem.title }}</div>
                      <div class="sbi-list-wrap">
                        <template v-for="(item, subIndex) in unitItem.items" :key="subIndex">
                          <button :class="['il-item', {'active': item.isSelect}]"
                                  @click="() => this.onChangeSettingItem(midIndex, subIndex, !item.isSelect)"
                          >
                            <span>{{ item.name }}</span>
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                  -->
                  <template v-if="this.selectTabIndex === 0">
                    <div class="sb-item">
                      <div class="sbi-title">문항 수</div>
                      <div class="sbi-prb-eval-cnt-wrap">
                        <p>전학년 소단원 개수</p>
                        <p>x</p>
                        <div class="pc-sel-wrap">
                          <select v-model="this.selectDiagCnt">
                            <option value="null" hidden>선택</option>
                            <option v-for="(name, index) in [1, 2, 3]" :key="index" :value="name">{{ name }}</option>
                          </select>
                          <span>배수</span>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else-if="this.selectTabIndex === 1">
                    <div class="sb-item">
                      <div class="sbi-title">문항 수&nbsp;&nbsp;&nbsp;&nbsp;<span>{{ this.selectUnitPrbMaxCnt }}</span></div>
                      <div class="sbi-btn-list-wrap">
                        <button v-for="(prbCnt, index) in [25, 50]" :key="index"
                                :class="['lw-item', {'active': this.selectUnitPrbMaxCnt == prbCnt}]"
                                @click="() => this.selectUnitPrbMaxCnt = prbCnt">
                          <span>{{ prbCnt }}</span>
                        </button>
                      </div>
                      <div class="sbi-pc-slider-wrap">
                        <button @click="() => this.onChangeUnitPrbCntPrv()">
                          <span>- 5</span>
                        </button>
                        <div>
                          <slider
                              :modelValue="this.selectUnitPrbMaxCnt"
                              :width="'100%'"
                              :height="6"
                              :handleScale="2.5"
                              :min="0"
                              :max="50"
                              :step="1"
                              :alwaysShowHandle="true"
                              color="#F9D286"
                              trackColor="#fcf9f0"
                              :tooltip="true"
                              :tooltipColor="'#000000'"
                              :sticky="true"
                              :flip="false"
                              @change="(value) => this.selectUnitPrbMaxCnt = value"
                          />
                          <div>
                            <span>0</span>
                            <span>50</span>
                          </div>
                        </div>
                        <button @click="() => this.onChangeUnitPrbCntNext()">
                          <span>+ 5</span>
                        </button>
                      </div>
                    </div>
                    <div class="sb-item">
                      <div class="sbi-title">문제 타입</div>
                      <div class="sbi-btn-list-wrap">
                        <button v-for="(unitPrbType, index) in this.unitPrbTypes" :key="index"
                                :class="['lw-item', {'active': this.selectUnitPrbTypeIndex == index}]"
                                @click="() => this.selectUnitPrbTypeIndex = index">
                          <span>{{ unitPrbType }}</span>
                        </button>
                      </div>
                    </div>
                    <div class="sb-item">
                      <div class="sbi-prb-eval-cnt-wrap">
                        <div class="sbi-title">심화문제</div>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <div class="pc-sel-wrap">
                          <select v-model="this.selectDiffPrbCnt">
                            <option value="null" hidden>선택</option>
                            <option v-for="(name, index) in [...Array(10).keys().map(i => i + 1)]" :key="index" :value="name">{{ name }}</option>
                          </select>
                          <span>문항</span>
                        </div>
                      </div>
                    </div>
                    <div class="sb-item">
                      <div class="sbi-prb-eval-cnt-wrap">
                        <div class="sbi-title">취약문제</div>
                        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                        <div class="pc-sel-wrap">
                          <select v-model="this.selectWeakPrbCnt">
                            <option value="null" hidden>선택</option>
                            <option v-for="(name, index) in [...Array(10).keys().map(i => i + 1)]" :key="index" :value="name">{{ name }}</option>
                          </select>
                          <span>문항</span>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="sec-total-info-wrap">
                  <template v-if="this.selectTabIndex === 0">
                    <div class="tiw-item">
                      <p>문제 수</p>
                      <span>{{ this.diagTotalPrbCnt }}개</span>
                    </div>
                  </template>
                  <template v-else-if="this.selectTabIndex === 1">
                    <div class="tiw-item">
                      <p>문제 수</p>
                      <span>{{ this.unitTotalPrbCnt }}개</span>
                    </div>
                    <div class="tiw-item">
                      <p>적용된 유형수</p>
                      <span>{{ this.unitTotalPrbPtnCnt }}개</span>
                    </div>
                  </template>
                </div>
                <div class="sec-button-wrap" @click="() => this.onSubmit()">
                  <button>
                    <span>작성 완료</span>
                  </button>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </V2Wrapper>
  </div>

  <Teleport to="body">
    <LoadingDialog
        :is-show="this.isShowLoadingDialog"
    />
  </Teleport>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import SelfStudyService from "@/services/selfStudy.service";
import ListPaginator from "@/components/ListPaginator.vue";
import SelfLearningModeTreeList from "@/components/SelfLearningModeTreeList.vue";
import ErrorMsgBox from "@/components/ErrorMsgBox.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import V2TreeList from "@/components/V2TreeList.vue";
import {mapActions, mapGetters} from "vuex";
import utils from "@/utils";
import models from "@/models";

export default {
  name: 'StudentSelfLearningModeList',
  components: {LoadingDialog, ErrorMsgBox, SelfLearningModeTreeList, ListPaginator, LoadingBox, V2Wrapper, V2TreeList,},
  data() {
    return {
      errorMsg: null,
      isShowLoadingDialog: false,

      selectRange: null,
      selectSubRange: null,

      tabs: [
        '진단평가',
        '단원평가',
      ],
      selectTabIndex: 0,

      selectDiagCnt: 3,
      selectDiagPtnIds: [],
      selectDiagPtnPreCnt: 0,

      selectUnitPrbMaxCnt: 25,
      unitPrbTypes: models.problemTypeList(),
      selectUnitPrbTypeIndex: 0,
      selectDiffPrbCnt: 3,
      selectWeakPrbCnt: 3,
      selectUnitPrbCnts: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    onChangeUnitPrbCntPrv() {
      let nextValue = this.selectUnitPrbMaxCnt - 5;
      this.selectUnitPrbMaxCnt = nextValue > 0 ? nextValue : 0;
    },
    onChangeUnitPrbCntNext() {
      let nextValue = this.selectUnitPrbMaxCnt + 5;
      this.selectUnitPrbMaxCnt = nextValue > 50 ? 50 : nextValue;
    },
    async initData() {
      const academyUserId = this.getUser?.academy_user?.id;
      if (academyUserId === null) {
        this.errorMsg = '학원 ID를 알 수 없습니다';
        return;
      }
      const selectedAcademyClassId = this.getStudentAcademyUserId;
      if (selectedAcademyClassId === null) {
        this.errorMsg = '반을 먼저 선택해주세요';
      }
    },
    onChangeSettingItem(midIndex, subIndex, isSelect) {
      this.changeSettingItemSelect({
        mainIndex: this.selectTabIndex,
        midIndex: midIndex,
        subIndex: subIndex,
        isSelect: isSelect,
      });
    },
    async onSubmit() {
      if (this.selectTabIndex === 0) {
        await this.onSubmitDiag();
      } else {
        await this.onSubmitUnit();
      }
    },
    async onSubmitDiag() {
      const academyClassId = this.getSelectedAcademyClassId;
      if (academyClassId === null) {
        this.$toast.error('반을 먼저 선택해주세요');
        return;
      }

      const selectRange = this.selectRange;
      const selectSubRange = this.selectSubRange;
      if (selectRange == null || selectSubRange == null) {
        this.$toast.error('범위를 모두 선택해주세요');
        return;
      }

      const selectDiagPtnIds = this.selectDiagPtnIds;
      if (selectDiagPtnIds.length == 0) {
        this.$toast.error('최소 한 개 이상 소단원을 선택해주세요');
        return;
      }

      const selectDiagPtnPreCnt = this.selectDiagPtnPreCnt;
      if (selectDiagPtnPreCnt === 0) {
        this.$toast.error('선택한 소단원에 연결된 이전 소단원이 없습니다');
        return;
      }

      const selectDiagCnt = this.selectDiagCnt;
      const settingDiag = this.getSettingDiag;

      this.isShowLoadingDialog = true;
      const res = await SelfStudyService.createStudentDiagEval({// 변경 필요
            'academy_class_id': academyClassId,
            'ptn_ids': selectDiagPtnIds.join('_'), // 선택한 소단원 유형 ID 리스트
            'prb_mul': selectDiagCnt,
      });
      this.isShowLoadingDialog = false;

      if (res) {
        this.$toast.success('작성 완료되었습니다');
        this.$router.push({ name: 'TeacherDashboard' });
      } else {
        this.$toast.error('작성 중 오류가 발생하였습니다');
      }
    },
    async onSubmitUnit() {
      const academyClassId = this.getSelectedAcademyClassId;
      if (academyClassId === null) { this.$toast.error('반을 먼저 선택해주세요'); return; }

      const selectRange = this.selectRange;
      const selectSubRange = this.selectSubRange;
      if (selectRange == null || selectSubRange == null) { this.$toast.error('범위를 모두 선택해주세요'); return; }

      const selectDiagPtnIds = this.selectDiagPtnIds;
      if (selectDiagPtnIds.length == 0) { this.$toast.error('최소 한 개 이상 소단원을 선택해주세요'); return; }

      const prbCnt = this.unitTotalPrbCnt;
      if (prbCnt <= 0) { this.$toast.error('문항 수가 1개 이상 되도록 선택해주세요'); return; }

      const settingDiag = this.getSettingDiag;
      const prbType = this.selectUnitPrbTypeIndex === 0 ? null : this.unitPrbTypes[this.selectUnitPrbTypeIndex];
      const diffPrbCnt = this.selectDiffPrbCnt;
      const weakPrbCnt = this.selectWeakPrbCnt;

      this.isShowLoadingDialog = true;
      const res = await SelfStudyService.createTeacherUnitEval({ // 변경 필요
            'academy_class_id': academyClassId,
            'ptn_ids': selectDiagPtnIds.join('_'), // 선택한 소단원 유형 ID 리스트
            'prb_cnt': prbCnt, // 문항 수
            'prb_type': prbType, // 문제 타입
            'diff_prb_cnt': diffPrbCnt, // 심화 문항 수
            'weak_prb_cnt': weakPrbCnt, // 취약 문항 수
      });
      this.isShowLoadingDialog = false;

      if (res) {
        this.$toast.success('작성 완료되었습니다');
        this.$router.push({ name: 'TeacherDashboard' });
      }
    },
    ...mapActions('settings', [
      'changeSettingItemSelect',
    ]),
  },
  computed: {
    diagTotalPrbCnt() {
      return utils.numberFormat(this.selectDiagPtnPreCnt * this.selectDiagCnt);
    },
    settingItems() {
      return this.selectTabIndex === 0 ? this.getSettingDiag : this.getSettingUnit;
    },
    unitTotalPrbCnt() {
      const selectUnitPrbCnts = this.selectUnitPrbCnts ?? [];

      // 문제 타입 필터링
      // 전체 필터링 여부 확인
      const typeIndex = this.selectUnitPrbTypeIndex;
      let prbCnt = 0;
      if (typeIndex === 0) {
        prbCnt = selectUnitPrbCnts.reduce((acc, cur) => {
          return acc + cur;
        }, 0);
      } else {
        prbCnt = selectUnitPrbCnts[typeIndex - 1]; // 전체 항목 제외한 인덱스
      }

      // 문항 수 필터링
      const maxPrbCnt = this.selectUnitPrbMaxCnt;
      if (prbCnt > maxPrbCnt) {
        prbCnt = maxPrbCnt;
      }

      return utils.numberFormat(prbCnt ?? 0);
    },
    unitTotalPrbPtnCnt() {
      return utils.numberFormat(this.selectDiagPtnIds.length);
    },
    ...mapGetters('settings', [
      'getSettingDiag',
      'getSettingUnit',
    ]),
    ...mapGetters('auth', [
      'getUser', 'getStudentAcademyUserId',
    ]),
    ...mapGetters('classStore', [
      'getSelectedAcademyClassId',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.student-self-learning-mode-list-wrap {
  .slmw-inner {
    height: 100%;
    background-color: rgba(243, 211, 144, .15);

    .v2-base-container {
      height: 100%;
      padding: 15px;

      .wi-body-wrap {
        display: flex;
        width: 100%;
        height: 100%;
        gap: 15px;

        @include mobile {
          flex-direction: column;
        }

        .sec-left-wrap {
          flex: 450;
          border-radius: 10px;
          border: 1px solid #DBDBDB;
          padding: 15px;
          display: flex;
          flex-direction: column;
          background-color: white;

          @include mobile {
            flex: unset;
            height: 300px;
            padding: 10px;
          }

          .sec-tab-wrap {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 10px;
            gap: 20px;

            @include mobile {
              padding: 5px;
              gap: 10px;
            }

            &>p {
              font-weight: 600;
              font-size: 14px;
              color: black;
              line-height: 1;

              @include tablet {
                font-size: 10px;
              }
            }

            .st-divider {
              width: 1px;
              height: 100%;
              background-color: #797979;
            }

            .select-wrap {
              flex: 1;
              display: flex;
              gap: 10px;
              align-items: center;

              @include tablet {
                gap: 5px;
              }

              &>select {
                border-radius: 5px;
                border: 1px solid #DBDBDB;
                font-weight: 500;
                font-size: 14px;
                color: black;
                padding: 10px 25px 10px 10px;
                appearance: none;
                -webkit-appearance: none;
                background: url(~@/assets/images/select.png) no-repeat right 5px center;
                background-size: 15px 15px;

                @include tablet {
                  font-size: 10px;
                  padding: 5px 20px 5px 5px;
                  background-size: 10px 10px;
                }
              }

              &>img {
                width: 25px;
                height: 25px;
                object-fit: contain;

                @include tablet {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }

          .sec-list-wrap {
            width: 100%;
            flex: 1;
          }
        }

        .sec-right-wrap {
          flex: 340;
          border-radius: 10px;
          border: 1px solid #DBDBDB;
          display: flex;
          flex-direction: column;
          padding: 15px;
          background-color: white;

          .sec-tab-list {
            width: 100%;
            border-bottom: 1px solid #DBDBDB;
            display: flex;
            gap: 15px;

            @include tablet {
              gap: 10px;
            }

            .tl-item {
              display: flex;
              flex-direction: column;
              align-items: center;
              cursor: pointer;

              &>p {
                font-weight: 500;
                font-size: 13px;
                color: #797979;
                text-align: center;

                @include tablet {
                  font-size: 10px;
                }
              }

              &>div {
                margin-top: 13px;
                width: 100%;
                height: 3px;
                background-color: transparent;

                @include tablet {
                  margin-top: 10px;
                  height: 2px;
                }
              }

              &.active {
                &>p {
                  font-weight: 600;
                  color: #F8B633;
                }

                &>div {
                  background-color: #F8B633;
                }
              }
            }
          }

          .sec-body-wrap {
            width: 100%;
            flex: 1;
            padding: 25px 0;
            display: flex;
            flex-direction: column;
            gap: 25px;
            overflow-y: auto;

            @include tablet {
              padding: 20px 0;
              gap: 20px;
            }

            .sb-item {
              width: 100%;
              display: flex;
              flex-direction: column;
              gap: 10px;

              .sbi-title {
                font-weight: 600;
                font-size: 13px;
                color: black;

                @include tablet {
                  font-size: 10px;
                }

                &>span {
                  font-weight: 400;
                }
              }

              .sbi-list-wrap {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                @include tablet {
                  gap: 5px;
                }

                .il-item {
                  border: 1px solid #F9D286;
                  cursor: pointer;
                  border-radius: 30px;
                  padding: 7px 13px;
                  display: flex;

                  @include tablet {
                    padding: 5px 10px;
                  }

                  &>span {
                    font-weight: 500;
                    font-size: 12px;
                    color: black;
                    text-align: center;
                    line-height: 1.6;

                    @include tablet {
                      font-size: 9px;
                    }
                  }

                  &.active {
                    background-color: #F9D286;
                  }
                }
              }

              .sbi-prb-eval-cnt-wrap {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 7px;

                &>p {
                  font-weight: 500;
                  font-size: 12px;
                  color: black;

                  @include tablet {
                    font-size: 9px;
                  }
                }

                .pc-sel-wrap {
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  &>select {
                    border-radius: 5px;
                    border: 1px solid #DBDBDB;
                    font-weight: 500;
                    color: black;
                    font-size: 13px;
                    padding: 5px 15px 5px 10px;
                    appearance: none;
                    -webkit-appearance: none;
                    background: url(~@/assets/images/select.png) no-repeat right 1px center;
                    background-size: 15px 15px;
                    text-align: center;

                    @include tablet {
                      font-size: 10px;
                      padding: 3px 12px 3px 7px;
                      background-size: 10px 10px;
                    }
                  }

                  &>span {
                    font-weight: 500;
                    color: black;
                    font-size: 12px;

                    @include tablet {
                      font-size: 9px;
                    }
                  }
                }
              }

              &>.sbi-btn-list-wrap {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                &>.lw-item {
                  border: 1px solid #F9D286;
                  cursor: pointer;
                  border-radius: 7px;
                  padding: 7px 20px;
                  display: flex;
                  min-width: 70px;
                  justify-content: center;
                  align-items: center;

                  &>span {
                    font-weight: 500;
                    font-size: 12px;
                    color: black;
                    text-align: center;
                    line-height: 1.6;
                  }

                  &.active {
                    background-color: #F9D286;
                  }
                }
              }

              &>.sbi-pc-slider-wrap {
                width: 100%;
                display: flex;
                align-items: center;
                gap: 12px;
                max-width: 350px;

                &>button {
                  display: flex;
                  padding: 8px 10px;
                  border: 1px solid #F9D286;
                  cursor: pointer;
                  border-radius: 7px;

                  &>span {
                    font-size: 13px;
                    line-height: 1.2;
                  }
                }

                &>div {
                  flex: 1;
                  display: flex;
                  flex-direction: column;

                  &>div {
                    &:last-child {
                      width: 100%;
                      display: flex;
                      gap: 10px;
                      justify-content: space-between;
                      margin-top: 2px;

                      &>span {
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 1.2;
                        text-align: center;
                        color: #000000;
                      }
                    }
                  }
                }
              }
            }
          }

          .sec-total-info-wrap {
            width: 100%;
            border-top: 1px solid #DBDBDB;
            padding-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 7px;

            .tiw-item {
              width: 100%;
              display: flex;
              justify-content: space-between;

              &>p,
              &>span {
                font-weight: 600;
                font-size: 13px;

                @include tablet {
                  font-size: 10px;
                }
              }
            }
          }

          .sec-button-wrap {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            &>button {
              max-width: 130px;
              width: 100%;
              display: flex;
              justify-content: center;
              border-radius: 10px;
              background-color: #F9D286;
              border: 1px solid #F9D286;
              padding: 7px 13px;

              @include tablet {
                max-width: 110px;
                padding: 5px 10px;
              }

              &>span {
                text-align: center;
                font-weight: 500;
                font-size: 13px;
                line-height: 1.6;
                color: black;

                @include tablet {
                  font-size: 9px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
