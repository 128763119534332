<template>
  <div class="student-self-learning-mode-result-wrap">
    <V2Wrapper>
      <div class="sslml-inner">
        <div class="v2-base-container">
          <!--          <div class="li-head-wrap">-->
          <!--            <button @click="() => this.$router.push({ name: 'StudentSelfLearningModeEdit' })">-->
          <!--              <span>자가학습 생성하기</span>-->
          <!--            </button>-->
          <!--          </div>-->
          <div class="li-table-wrap">
            <div class="tw-line">
              <div class="tw-item">
                <p class="wi-text">유형</p>
              </div>
              <div class="tw-item">
                <p class="wi-text">출제자</p>
              </div>
              <div class="tw-item">
                <p class="wi-text">출제일시</p>
              </div>
              <div class="tw-item">
                <p class="wi-text">문제수</p>
              </div>
              <div class="tw-item">
                <p class="wi-text">완료상태</p>
              </div>
            </div>
            <template v-if="this.isLoading">
              <LoadingBox style="margin-top: 30px"/>
            </template>
            <template v-else>
              <template v-for="(item, index) in this.itemList" :key="index">
                <div class="tw-line"
                     @click="() => this.$router.push({ name: 'StudentSelfLearningModeDetail', params: {evalType: item.eval_type, evalStuId: item.id}, })">
                  <div class="tw-item">
                    <p class="wi-text">{{ item.d_type }}</p>
                  </div>
                  <div class="tw-item">
                    <p class="wi-text">{{ item.name }}</p>
                  </div>
                  <div class="tw-item">
                    <p class="wi-text">{{ item.create_dt }}</p>
                  </div>
                  <div class="tw-item">
                    <p class="wi-text">{{ item.prb_cnt }}</p>
                  </div>
                  <div class="tw-item">
                    <p class="wi-text">{{ item.state }}</p>
                  </div>
                </div>
              </template>
            </template>
          </div>
          <div class="li-paginator-wrap">
            <ListPaginator
                :page="this.paginatePage"
                :total-page="this.paginateTotalPage"
                :max-visible-page="this.paginateMaxVisiblePage"
                :on-page-change="this.onChangePage"
            />
          </div>
        </div>
      </div>
    </V2Wrapper>
  </div>
</template>

<script>
import V2Wrapper from "@/components/V2Wrapper.vue";
import SelfLearningModeResult from "@/components/SelfLearningModeResult.vue";
import ListPaginator from "@/components/ListPaginator.vue";
import LoadingBox from "@/components/LoadingBox.vue";
import SelfStudyService from "@/services/selfStudy.service";

export default {
  name: 'StudentSelfLearningModeResult',
  components: {LoadingBox, ListPaginator, SelfLearningModeResult, V2Wrapper,},
  data() {
    return {
      isLoading: true,
      itemList: [],

      paginateSize: 5,
      paginatePage: 1,
      paginateTotalPage: 1,
      paginateMaxVisiblePage: 1 + 5 + 5,
    };
  },
  mounted() {
    this.refreshList();
  },
  methods: {
    onChangePage(page) {
      this.paginatePage = page;
      this.refreshList();
    },
    async refreshList() {
      this.isLoading = true;

      const res = await SelfStudyService.getStudentSelfLeaningList(this.paginateSize, this.paginatePage);
      if (res !== null) {
        this.itemList = res.items;
        this.paginateSize = res.page_size;
        this.paginatePage = res.page;
        this.paginateTotalPage = res.total_pages;
      } else {
        this.$toast.error('리스트를 불러오는 중 오류가 발생하였습니다');
      }

      this.isLoading = false;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.student-self-learning-mode-result-wrap {
  .sslml-inner {
    min-height: 100%;
    padding-bottom: 30px;
    @include tablet {
      padding-bottom: 20px;
    }
    @include mobile {
      padding-bottom: 15px;
    }
    .v2-base-container {
      flex-direction: column;
      .li-head-wrap {
        margin-top: 30px;
        width: 100%;
        display: flex;
        gap: 5px;
        align-items: center;
        flex-wrap: wrap;
        @include tablet {
          margin-top: 20px;
          gap: 15px;
        }
        @include mobile {
          margin-top: 15px;
          gap: 10px;
        }
        &>button {
          margin-left: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #DBDBDB;
          box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.25);
          cursor: pointer;
          padding: 8px 15px;
          background-color: #{$defaultColor};
          @include mobile {
            padding: 8px 10px;
          }
          &>span {
            text-align: center;
            font-weight: 700;
            font-size: 15px;
            color: white;
            line-height: 1;
            white-space: nowrap;
            @include tablet {
              font-size: 13px;
            }
            @include mobile {
              font-size: 10px;
            }
          }
        }
      }
      .li-table-wrap {
        width: 100%;
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        @include mobile {
          margin-top: 20px;
        }
        .tw-line {
          width: 100%;
          display: flex;
          .tw-item {
            display: flex;
            padding: 15px 10px;
            justify-content: center;
            border-bottom: 1px solid #dddddd;
            align-items: center;
            @include tablet {
              padding: 12px 7px;
            }
            @include mobile {
              padding: 8px 5px;
            }
            .wi-text {
              width: 100%;
              color: #222222;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              font-size: 14px;
              @include tablet {
                font-size: 13px;
              }
              @include mobile {
                font-size: 9px;
              }
            }
            &:nth-child(1) {
              width: 150px;
              @include tablet {
                width: 100px;
              }
              @include mobile {
                width: 65px;
              }
            }
            &:nth-child(2) {
              flex: 1;
            }
            &:nth-child(3) {
              width: 150px;
              @include tablet {
                width: 100px;
              }
              @include mobile {
                width: 65px;
              }
            }
            &:nth-child(4) {
              flex: 1;
            }
            &:nth-child(5) {
              width: 150px;
              @include tablet {
                width: 100px;
              }
              @include mobile {
                width: 65px;
              }
            }
          }
          &:first-child {
            background-color: #f2f7ff;
            .tw-item {
              padding: 10px 10px;
              border-top: 1px solid #{$defaultColor};
              border-bottom: 1px solid #{$defaultColor};
              &>p {
                color: #{$defaultColor};
                font-weight: 700;
              }
            }
          }
          &:not(:first-child) {
            cursor: pointer;
            &:hover {
              background-color: #f8f8f8;
            }
          }
        }
      }

    }
  }
}
</style>
