<template>
  <div id="container">
    <div class="signup-student-wrap">
      <h1>회원가입</h1>
      <h2>학생</h2>

      <table>
        <colgroup>
          <col/>
          <col/>
        </colgroup>

        <tr>
          <th colspan="2">회원정보</th>
        </tr>
        <tr>
          <td>이메일 <b>* 필수항목</b></td>
          <td>
            <div class="td-item item-email">
              <input
                  v-model="emailFirst"
                  id="emailFirst"
                  autocomplete="off"
              />
              <span>@</span>
              <template v-if="isCustomEmailLast">
                <input
                    v-model="emailLast"
                    id="emailLast"
                    autocomplete="off"
                />
              </template>
              <template v-else>
                <CustomSelect
                    :options="emailOptions"
                    :onChangeValue="onChangeEmailLast"
                />
              </template>
            </div>
          </td>
        </tr>
        <tr v-if="!isSocial">
          <td>비밀번호 <b>* 필수항목</b></td>
          <td>
            <div class="td-item">
              <input v-model="password" id="password" type="password" autocomplete="off"/>
            </div>
          </td>
        </tr>
        <tr v-if="!isSocial">
          <td>비밀번호 확인</td>
          <td>
            <div class="td-item">
              <input v-model="passwordConfirm" id="password-confirm" type="password" autocomplete="off"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>이름 <b>* 필수항목</b></td>
          <td>
            <div class="td-item">
              <input v-model="name" id="name" type="text" autocomplete="off"/>
            </div>
          </td>
        </tr>
        <tr>
          <td>연락처 <b>* 필수항목</b></td>
          <td>
            <div class="td-item">
              <input
                  v-model="phone"
                  id="phone"
                  autocomplete="off"
                  :disabled="isSendVerifyPhone"
                  :readonly="isSendVerifyPhone"
                  placeholder="- 제외하여 입력해주세요"
              />
              <button v-if="!isSendVerifyPhone" @click="onVerifyPhone">인증번호 받기</button>
            </div>
          </td>
        </tr>
        <tr>
          <td></td>
          <td>
            <div class="td-item">
              <input v-model="phoneCode" id="phone-code" placeholder="인증번호를 입력해주세요." autocomplete="off"/>
            </div>
          </td>
        </tr>
      </table>


      <table>
        <colgroup>
          <col/>
          <col/>
        </colgroup>

        <tr>
          <th colspan="2">학원/학교 정보</th>
        </tr>
        <tr>
          <td>학원명 검색 <b>* 필수항목</b></td>
          <td>
            <div class="td-item">
              <v-select
                  :loading="isLoadingSchoolNames"
                  :options="academies"
                  v-model="this.selectAcademy">
                <template v-slot:no-options>해당되는 학원 명이 없습니다</template>
              </v-select>
            </div>
          </td>
        </tr>
      </table>

      <SignUpTerms
          :isAgreeServiceTerms="isAgreeServiceTerms"
          :onChangeService="(isChecked) => isAgreeServiceTerms = isChecked"
          :isAgreePersonalTerms="isAgreePersonalTerms"
          :onChangePersonal="(isChecked) => isAgreePersonalTerms = isChecked"
          :isAgreeAllTerms="isAgreeServiceTerms && isAgreePersonalTerms"
          :onChangeAll="(isChecked) => isAgreeServiceTerms = isAgreePersonalTerms = isChecked"
      />

      <button class="reg-btn" @click="this.onSubmit">회원가입</button>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import AcademyService from '../services/academy.service'
import utils from '../utils';
import AuthService from '../services/auth.service'
import SignUpTerms from '../components/SignUpTerms.vue';
import CustomSelect from "@/components/CustomSelect.vue";

export default {
  name: 'SignupTeacher',
  components: {CustomSelect, vSelect, SignUpTerms },
  data() {
    return {
      isLoadingSchoolNames: true,
      academies: [],

      emailFirst: null,
      emailLast: null,
      isCustomEmailLast: false,
      password: null,
      passwordConfirm: null,
      name: null,
      phone: null,
      isSendVerifyPhone: false,
      phoneCode: null,
      selectAcademy: null,
      verifyPhoneCode: null,

      isAgreeServiceTerms: false,
      isAgreePersonalTerms: false,
    }
  },
  mounted() {
    this.initData()
  },
  methods: {
    async onVerifyPhone() {
      if (!this.phone) { this.$toast.error("연락처를 입력해주세요") }
      else if (!utils.getPhoneRegex().test(this.phone)) { this.$toast.error("정확한 연락처를 형식에 맞게 입력해주세요") }
      else {
        const res = await AuthService.requestCheckPhone(this.phone);
        if (res) {
          this.verifyPhoneCode = res.code
          this.$toast.success("인증번호를 발송하였습니다");
          this.isSendVerifyPhone = true;
        }
      }
    },
    async onSubmit() {
      if (!this.email) { this.$toast.error("이메일을 입력해주세요") }
      else if (!utils.getEmailRegex().test(this.email)) { this.$toast.error("이메일 형식으로 입력해주세요") }
      else if (!this.isSocial && !this.password) { this.$toast.error("비밀번호를 입력해주세요") }
      else if (!this.isSocial && !this.passwordConfirm) { this.$toast.error("비밀번호 확인을 입력해주세요") }
      else if (!this.isSocial && this.password != this.passwordConfirm) { this.$toast.error("비밀번호가 다릅니다") }
      else if (!this.name) { this.$toast.error("이름을 입력해주세요") }
      else if (!this.phone) { this.$toast.error("연락처를 입력해주세요") }
      else if (!this.isSendVerifyPhone) { this.$toast.error("연락처 인증을 해주세요") }
      else if (!this.phoneCode) { this.$toast.error("연락처 인증번호를 입력해주세요") }
      else if (this.phoneCode !== this.verifyPhoneCode) { this.$toast.error("연락처 인증번호를 정확히 입력해주세요") }
      else if (!this.selectAcademy) { this.$toast.error("학원을 선택해주세요") }
      else if (this.isAgreeServiceTerms == false) { this.$toast.error("서비스 이용 약관에 동의해주세요") }
      else if (this.isAgreePersonalTerms == false) { this.$toast.error("개인정보 처리방침에 동의해주세요") }
      else {
        const res = await AuthService.signup({
          'email': this.email,
          'password': this.isSocial ? 'social123' : this.password,
          'name': this.name,
          'phone': this.phone,
          'phone_code': this.phoneCode,
          'select_academy_id': this.selectAcademy.id,
          'is_student': true,

          'type': this.$route.query?.type,
          'u_id': this.$route.query?.u_id,
        })

        if (res) {
          this.$toast.success('회원가입되었습니다.\n해당 아이디로 로그인해주세요\n학원 관리자 승인 시 학생 페이지를 이용할 수 있습니다')
          this.$router.push({ name: 'login', query: this.$route.query })
        }
      }
    },
    async initData() {
      const res = await AcademyService.getAcademyNames()
      const {list} = res
      if (list) {
        this.academies = list.map(item => {
          item['label'] = item.name
          return item
        })
      }
      this.isLoadingSchoolNames = false
    },
    onChangeEmailLast(value) {
      if (value == '직접입력') {
        this.isCustomEmailLast = true;
      } else {
        this.emailLast = value;
      }
    },
  },
  computed: {
    email() {
      if (!this.emailFirst || !this.emailLast) {
        return null;
      } else {
        return `${this.emailFirst}@${this.emailLast}`;
      }
    },
    isSocial() {
      return this.$route.query?.type && this.$route.query?.u_id
    },
    emailOptions() {
      return [
        'naver.com',
        'gmail.com',
        'hanmail.net',
        'nate.com',
        'kakao.com',
        '직접입력',
      ].map((e) => {
        return {
          value: e,
          name: e,
          isSelected: false,
        };
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.signup-student-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 108px 40px 142px;
  align-items: center;
  h1 {
    font-weight: 700;
    font-size: 45px;
    line-height: 51px;
    text-align: center;
    color: #000000;
  }
  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    text-align: center;
    color: #F8B633;
    margin-top: 25px;
  }
  table {
    margin-top: 66px;
    width: 100%;
    max-width: 650px;
    colgroup {
      col {
        &:nth-child(1) {
          width: 150px;
        }
      }
    }
    th {
      font-weight: 800;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
      color: #008ED5;
      padding: 15px 0;
      text-align: start;
    }
    td {
      font-weight: 700;
      font-size: 18px;
      line-height: 20px;
      color: #000000;
      text-align: start;
      padding: 10px 0;
      b {
        font-size: 8px;
        color: #FF0000;
      }
      &:first-child {
        vertical-align: -webkit-baseline-middle;
        padding-top: 25px;
      }
      .td-item {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 10px;
        input {
          flex: 1;
          background: #FFFFFF;
          border: 1px solid #999999;
          border-radius: 5px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: black;
          height: 50px;
          padding: 5px 23px;
          &:placeholder {
            color: #999999;
          }
        }
        button {
          background: #008ED5;
          border: 1px solid #008ED5;
          border-radius: 5px;
          width: 130px;
          height: 50px;
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
        }
        p {
          width: 100%;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #333333;
        }
        &.item-email {
          flex-wrap: nowrap;
          align-items: center;
          select {
            flex: 1;
            height: 50px;
            border: 1px solid #999999;
            font-size: 14px;
          }
        }
        .input-file {
          width: 100%;
          display: flex;
          align-items: center;
          input {
            display: none;
          }
          button {
            background: linear-gradient(180deg, rgba(206, 206, 206, 0) 0%, #CECECE 100%);
            border: 1px solid #A3A3A3;
            border-radius: 5px;
            width: 130px;
            height: 30px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
            margin-right: 16px;
          }
          span {
            max-width: 350px;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #333333;
          }
        }
      }
    }
    .file-tr {
      td {
        padding-top: 20px;
      }
    }
  }
  .reg-btn {
    margin-top: 72px;
    background: #008ED5;
    border: 1px solid #008ED5;
    border-radius: 5px;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    color: #FFFFFF;
    padding: 14px 10px;
    width: 100%;
    max-width: 650px;
    cursor: pointer;
  }
}

@media (max-width: 500px) {
  .signup-student-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    align-items: center;
    h1 {
      font-weight: 700;
      font-size: 25px;
      line-height: 1.2;
      text-align: center;
      color: #000000;
    }
    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 1.2;
      text-align: center;
      color: #F8B633;
      margin-top: 10px;
    }
    table {
      margin-top: 20px;
      width: 100%;
      max-width: 650px;
      colgroup {
        col {
          &:nth-child(1) {
            width: 70px;
          }
        }
      }
      th {
        font-weight: 800;
        font-size: 13px;
        line-height: 1.2;
        text-align: center;
        color: #008ED5;
        padding: 15px 0;
        text-align: start;
      }
      td {
        font-weight: 700;
        font-size: 10px;
        line-height: 1.2;
        color: #000000;
        text-align: start;
        padding: 5px 0;
        &:first-child {
          vertical-align: -webkit-baseline-middle;
          padding-top: 15px;
          display: flex;
          flex-wrap: wrap;
          b {
            width: 100%;
          }
        }
        .td-item {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          column-gap: 10px;
          row-gap: 5px;
          input {
            flex: 1;
            background: #FFFFFF;
            border: 1px solid #999999;
            border-radius: 5px;
            font-weight: 700;
            font-size: 10px;
            line-height: 1.2;
            color: black;
            height: 30px;
            padding: 5px 10px;
            &:placeholder {
              color: #999999;
            }
            &:disabled {
              background: #efefef;
            }
            &:read-only {
              background: #efefef;
            }
          }
          &.item-checkbox {
            label {
              margin-top: 8px !important;
              margin-left: 10px;
            }
          }
          button {
            background: #008ED5;
            border: 1px solid #008ED5;
            border-radius: 5px;
            width: 50px;
            height: 30px;
            font-weight: 700;
            font-size: 10px;
            line-height: 1.2;
            color: #FFFFFF;
          }
          p {
            width: 100%;
            font-weight: 400;
            font-size: 10px;
            line-height: 1.2;
            color: #333333;
          }
          .v-select {
            height: 30px;
          }
          &.item-email {
            flex-wrap: nowrap;
            align-items: center;
            select {
              font-size: 10px;
              height: 30px;
              padding: 5px 25px 5px 10px;
            }
          }
        }
      }
      .file-tr {
        td {
          padding-top: 10px;
        }
      }
    }
    .reg-btn {
      margin-top: 30px;
      background: #008ED5;
      border: 1px solid #008ED5;
      border-radius: 5px;
      font-weight: 700;
      font-size: 13px;
      line-height: 1.2;
      text-align: center;
      color: #FFFFFF;
      padding: 14px 10px;
      width: 100%;
      max-width: 650px;
      cursor: pointer;
    }
  }
}
</style>
