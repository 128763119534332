<template>
  <div id="container">
    <div class="info-wrap">
      <img src="@/assets/images/min-window-info-icon.png" alt=""/>
      <h1>학원 승인 안내</h1>
      <p>해당 앱은 학원 승인 후 이용 가능합니다.<br/>학원 관리자에게 문의해주세요.</p>

      <button
          class="logout-btn"
          @click="this.signOutEvent"
      >
        로그아웃
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        signOutEvent() {
            this.signout()
            this.changeAcademyClassId(null);
            this.$router.push({ name: 'login' })
        },
        ...mapActions('auth', ['signout']),
        ...mapActions('classStore', ['changeAcademyClassId']),
    },
}
</script>

<style lang="scss" scoped>
.info-wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  img {
    width: 26px;
    object-fit: contain;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 24px;
  }
  p {
    font-size: 16px;
    color: rgb(112, 112, 112);
    text-align: center;
  }
  .logout-btn {
    margin-top: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;
    color: black;
  }
}
</style>
