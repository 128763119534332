<template>
  <div :class="['popup', {'hide': !this.isVisible}]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        <h1>{{ this.workbookId ? '교재 출제하기' : '학습지 출제하기' }}</h1>
        <h2>{{ this.subTitle }}</h2>

        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div class="body-contents">
        <div class="select-wrap">
          <div class="left-wrap">
            <div class="search-input">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="search" class="react-time-search-icon css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(159, 159, 159);"><path fill="#9F9F9F" d="M12.917 11.667h-.659l-.233-.225c.817-.95 1.308-2.184 1.308-3.525 0-2.992-2.425-5.417-5.416-5.417C4.925 2.5 2.5 4.925 2.5 7.917c0 2.991 2.425 5.416 5.417 5.416 1.341 0 2.575-.491 3.525-1.308l.225.233v.659l4.166 4.158 1.242-1.242-4.158-4.166zm-5 0c-2.075 0-3.75-1.675-3.75-3.75s1.675-3.75 3.75-3.75 3.75 1.675 3.75 3.75-1.675 3.75-3.75 3.75z"></path></svg>
              <input type="text" placeholder="학생 이름 검색" v-model="this.keyword"/>
              <button @click="() => this.keyword = null">
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 16px; color: rgb(192, 192, 192);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
              </button>
            </div>

            <div class="list-tab">
              <div :class="['tab-item', {'active': this.tabIndex == 0}]" @click="() => this.tabIndex = 0">
                <span>학년</span>
              </div>
              <div :class="['tab-item', {'active': this.tabIndex == 1}]" @click="() => this.tabIndex = 1">
                <span>반</span>
              </div>
            </div>
            <div class="list-outer-wrap">
              <div class="list-inner-wrap">
                <template v-if="this.isLoading">
                  <div class="empty-text">
                    <span>데이터를 불러오는 중입니다.</span>
                  </div>
                </template>
                <template v-else>
                  <template v-if="this.targetGroupAcademyUserCnt == 0">
                    <div class="empty-text">
                      <span>반이 없거나 등록된 학생이 없습니다.<br/>추가 후 진행해주세요</span>
                    </div>
                  </template>
                  <template v-else>
                    <template v-if="this.targetGroupUnselectedAcademyUserCnt == 0">
                      <div class="empty-text">
                        <span>모든 학생을 추가하였습니다.</span>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="this.targetGroups.length == 0">
                        <div class="empty-text">
                          <span>조건에 맞는 결과가 없습니다.<br/>다시 입력해주세요.</span>
                        </div>
                      </template>
                      <template v-else>
                        <TakeTestPopupListItem
                            v-for="(group, index) in this.targetUnselectedGroups"
                            :key="index"
                            :group="group"
                            :on-toggle-expand="(isExpand) => this.onExpandGroupId(group.groupId, isExpand)"
                            :on-click-group-right-btn="() => group.academyUsers.forEach(i => i.isSelected = true)"
                            :on-click-academy-user="(academyUser) => academyUser.isSelected = true"
                            :is-expand="this.expandGroupList.findIndex(i => i == group.groupId) >= 0"
                        />
                      </template>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </div>

          <div class="right-wrap">
            <div class="list-header">
              <p>선택된 학생</p>
              <div class="selected-student-names">
                <div class="name-outer-list">
                  <div class="name-inner-list">
                    <template v-if="this.targetGroupSelectedAcademyUsers.length == 0">
                      없음
                    </template>
                    <template v-else>
                      {{ this.targetGroupSelectedAcademyUsers.map(au => au.name).join(', ') }}
                    </template>
                  </div>
                </div>
              </div>
            </div>

            <div class="list-outer-wrap">
              <div class="list-inner-wrap">
                <template v-if="this.targetGroupSelectedAcademyUsers.length == 0">
                  <div class="empty-text">
                    <span>
                      왼쪽의
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" data-svg-name="add_circle" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 20px; color: rgb(192, 192, 192); margin-left: 6px; margin-right: 4px;"><path fill="#00ABFF" d="M10 1.667C5.4 1.667 1.667 5.4 1.667 10S5.4 18.333 10 18.333 18.333 14.6 18.333 10 14.6 1.667 10 1.667zm4.167 9.166h-3.334v3.334H9.167v-3.334H5.833V9.167h3.334V5.833h1.666v3.334h3.334v1.666z"></path></svg>
                      를
                      <br/>
                      눌러 학생을 선택하세요.
                    </span>
                  </div>
                </template>
                <template v-else>
                  <TakeTestPopupListItem
                      v-for="(group, index) in this.targetSelectedGroups"
                      :key="index"
                      :group="group"
                      :on-toggle-expand="(isExpand) => this.onExpandGroupId(group.groupId, isExpand)"
                      :on-click-group-right-btn="() => group.academyUsers.forEach(i => i.isSelected = false)"
                      :on-click-academy-user="(academyUser) => academyUser.isSelected = false"
                      :is-add-btn="false"
                      :is-expand="this.expandGroupList.findIndex(i => i == group.groupId) >= 0"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <TakeTestPopupWrongItem
          :isVisible="this.isVisible"
          :on-changed-wrong-items="(value) => this.wrongItems = value"
          :isAnsSev="this.isAnsSev"
          :onChangedIsAnsSev="(value) => this.isAnsSev = value"
        />
      </div>

      <div class="btn-list">
        <button @click="onSubmit">선택 완료</button>
      </div>
    </div>
  </div>
  <LoadingDialog
      :is-show="this.isLoading"
  />
</template>

<script>
import academyService from '../services/academy.service'
import studentWorkService from '../services/studentWork.service'
import CustomSelect from "@/components/CustomSelect.vue";
import TakeTestPopupListItem from "@/components/TakeTestPopupListItem.vue";
import LoadingDialog from "@/components/LoadingDialog.vue";
import TakeTestPopupWrongItem from "@/components/TakeTestPopupWrongItem.vue";

export default {
  name: 'TakeTestPopup',
  components: {
    TakeTestPopupWrongItem,
    LoadingDialog,
    TakeTestPopupListItem,
    CustomSelect
  },
  props: {
    isVisible: {type: Boolean, default: false},
    onClose: Function,
    worksheetId: Number,
    workbookId: Number,
    subTitle: String,
    //isAnsSev: Boolean,
  },

  watch: {
    async isVisible() {
      if (this.isVisible) {
        this.isLoading = true;
        await this.initData();
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      schoolYearGroups: [],
      classGroups: [],
      keyword: null,
      tabIndex: 0,
      isLoading: false,
      expandGroupList: [],
      /** @type {WrongItem[]} */
      wrongItems: [],
      isAnsSev: true,
    };
  },
  methods: {
    onExpandGroupId(groupId, isExpand) {
      const expandGroupList = this.expandGroupList;
      const findIndex = expandGroupList.findIndex(i => i == groupId);
      if (isExpand) {
        if (findIndex < 0) {
          expandGroupList.push(groupId);
        }
      } else {
        if (findIndex >= 0) {
          expandGroupList.splice(findIndex, 1);
        }
      }
    },
    async onSubmit() {
      const academyUsers = this.targetGroupSelectedAcademyUsers;
      if (academyUsers.length == 0) { this.$toast.error('최소 한 명 이상의 학생을 선택해주세요'); return; }
      const academyUserIdList = academyUsers.map(au => au.id);

      this.isLoading = true;
      try {
        await studentWorkService.createStudentWork({
          'academy_user_id_list': academyUserIdList,
          'worksheet_id': this.worksheetId,
          'workbook_id': this.workbookId,
          'is_ans_sev': this.isAnsSev,
          'wrong_items': this.wrongItems.map(e => {
            return {
              'max': e.max,
              'multiple': e.multiple,
            };
          }),          
        })

        this.$toast.success('출제하였습니다')
        this.onClose()
      } catch (e) {
        if (e?.response?.status != 400) {
          this.$toast.error('출제 중 오류가 발생하였습니다')
        }
      }
      this.isLoading = false;
    },
    async initData() {
      this.tabIndex = 0;
      this.keyword = null;
      this.schoolYearGroups = [];
      this.classGroups = [];
      this.expandGroupList = [];

      let academyClasses = [];
      let academyUsers = [];

      await Promise.all([
        academyService.getMyAcademyClasses(true).then((e) => academyClasses = e ?? []),
        academyService.getMyAcademyUsers(true).then((e) => academyUsers = e ?? []),
      ]);

      const schoolYearGroups = [];
      const classGroups = [];
      let groupId = 0;
      if (!academyUsers || academyUsers.length === 0) return;

      // 반 초기화
      for (const academyClass of academyClasses) {
        classGroups.push({
          'groupId': groupId++,
          'groupName': academyClass.name,
          'academyClass': academyClass,
          'academyUsers': [],
        });
      }

      for (const academyUser of academyUsers) {
        const schoolType = academyUser.school_type;
        const schoolYear = academyUser.school_year;

        // 학년 추가
        let findIndex = schoolYearGroups.findIndex(i => i.schoolType == schoolType && i.schoolYear == schoolYear);
        if (findIndex >= 0) {
          schoolYearGroups[findIndex].academyUsers.push(Object.assign({
          'isSelected': false,
        }, academyUser));
        } else {
          schoolYearGroups.push({
            'groupId': groupId++,
            'groupName': `${schoolType}${schoolYear}`,
            'schoolType': schoolType,
            'schoolYear': schoolYear,
            'academyUsers': [Object.assign({
              'isSelected': false,
            }, academyUser)],
          });
        }

        // 들어가 있는 반에 학생 추가
        for (const academyClass of academyClasses) {
          const studentIds = academyClass.student_ids ?? [];
          const isFoundUser = studentIds.findIndex(i => i == academyUser.id) > -1;
          if (isFoundUser) {
            findIndex = classGroups.findIndex(i => i.academyClass.id == academyClass.id);
            if (findIndex > -1) {
              classGroups[findIndex].academyUsers.push(Object.assign({
                'isSelected': false,
              }, academyUser));
            }
          }
        }
      }

      this.schoolYearGroups = schoolYearGroups;
      this.classGroups = classGroups;
    },
  },
  computed: {
    targetGroupAcademyUserCnt() {
      let cnt = 0;
      this.targetGroups.forEach(group => {
        cnt += group.academyUsers.length;
      });
      return cnt;
    },
    targetGroupSelectedAcademyUsers() {
      const resultList = [];
      this.targetGroups.forEach(group => {
        group.academyUsers.filter(au => !!au.isSelected).forEach(au => {
          resultList.push(au);
        });
      });
      return resultList;
    },
    targetGroupUnselectedAcademyUserCnt() {
      let cnt = 0;
      this.targetGroups.forEach(group => {
        cnt += group.academyUsers.filter(au => !au.isSelected).length;
      });
      return cnt;
    },
    targetGroups() {
      return this.tabIndex == 0 ? this.schoolYearGroups : this.classGroups;
    },
    targetUnselectedGroups() {
      const resultGroups = [];
      const keyword = this.keyword;
      this.targetGroups.forEach(group => {
        const academyUsers = group.academyUsers.filter(au => {
          if (keyword != null && keyword.length > 0) {
            if (!au.name.includes(keyword)) {
              return false;
            }
          }
          return !au.isSelected;
        });
        if (academyUsers.length > 0) {
          resultGroups.push(Object.assign({}, {
            'groupId': group.groupId,
            'groupName': group.groupName,
            'academyUsers': academyUsers,
          }));
        }
      });
      return resultGroups;
    },
    targetSelectedGroups() {
      const resultGroups = [];
      this.targetGroups.forEach(group => {
        const academyUsers = group.academyUsers.filter(au => {
          return au.isSelected;
        });
        if (academyUsers.length > 0) {
          resultGroups.push(Object.assign({}, {
            'groupId': group.groupId,
            'groupName': group.groupName,
            'academyUsers': academyUsers,
          }));
        }
      });
      return resultGroups;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/values";

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: calc(100% - 40px - 40px);
    min-width: 600px;
    height: 100%;
    max-height: calc(100% - 24px - 24px);
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      h1 {
        font-weight: bold;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.01em;
      }
      h2 {
        margin-left: 12px;
        color: rgb(112, 112, 112);
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.002em;
      }
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      flex: 1;
      padding: 24px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      overflow-y: auto;
      .select-wrap {
        width: 100%;
        display: flex;
        gap: 24px;
        min-height: 400px;
        .left-wrap,
        .right-wrap {
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .left-wrap {
          .search-input {
            width: 100%;
            display: flex;
            padding: 10px;
            border: 1px solid rgb(224, 224, 224);
            border-radius: 4px;
            height: 48px;
            align-items: center;
            margin-bottom: 10px;
            svg {
              width: 17px;
              height: 17px;
            }
            input {
              flex: 1;
              border: 0 none;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: -0.002em;
              color: rgb(51, 51, 51);
              &::placeholder {
                color: rgb(210,210,210);
              }
            }
          }
          .list-tab {
            width: 100%;
            display: flex;
            .tab-item {
              flex: 1;
              cursor: pointer;
              background-color: rgb(250, 250, 250);
              border: 1px solid rgb(232, 232, 232);
              text-align: center;
              height: 40px;
              border-bottom-width: 0;
              display: flex;
              span {
                margin: auto;
              }
              &.active {
                background-color: white;
              }
            }
          }
        }
        .right-wrap {
          .list-header {
            width: 100%;
            display: flex;
            border: 1px solid rgb(192, 192, 192);
            border-bottom-width: 0;
            height: 50px;
            align-items: center;
            padding: 0 10px;
            gap: 6px;
            p {
              font-weight: bold;
              font-size: 14px;
              line-height: 21px;
              white-space: nowrap;
            }
            .selected-student-names {
              flex: 1;
              position: relative;
              height: 100%;
              .name-outer-list {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow-x: auto;
                .name-inner-list {
                  color: rgb(192, 192, 192);
                  font-size: 14px;
                  line-height: 21px;
                  letter-spacing: -0.002em;
                  white-space: nowrap;
                  padding-top: 13px;
                }
              }
            }
          }
        }
        .list-outer-wrap {
          margin-top: 0;
          flex: 1;
          width: 100%;
          display: flex;
          border: 1px solid rgb(192, 192, 192);
          position: relative;
          overflow-y: auto;
          .list-inner-wrap {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            .empty-text {
              margin: auto;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: -0.002em;
              text-align: center;
            }
          }
        }
      }
    }
    .btn-list {
      border-top: 1px solid rgb(224, 224, 224);
      width: 100%;
      display: flex;
      gap: 8px;
      justify-content: flex-end;
      align-items: center;
      padding: 10px 24px;
      &>button {
        align-items: center;
        justify-content: center;
        display: flex;
        cursor: pointer;
        width: 150px;
        height: 40px;
        border-radius: 4px;
        transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
        background-color: #008ED5;
        color: white;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}
</style>
