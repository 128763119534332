<template>
  <div v-if="filterData"
       class="filter-wrap">
    <h1 v-if="filterData.title">
      <span>{{ filterData.title }}</span>
    </h1>
    <slot name="firstLayout"></slot>
    <div v-if="!filterData.isHiddenList"
         :class="['filter-list', {'un-flex': this.filterData.isUnFlexList}]">
      <template v-for="(filter, index) in filterData.list" :key="index">
        <CustomSelect v-if="filter.type == 'select'"
                      :addClass="filter.addClass"
                      :hiddenName="filter.name"
                      :options="filter.options"
                      @change="(event) => filter.onChange ? filter.onChange(event.target.value) : ''"/>
        <CustomCheckbox v-if="filter.type == 'checkbox'"
                        :class="['filter-cb', filter.addClass]"
                        :isChecked="filter.value"
                        :onChange="(checked) => filter.onChange ? filter.onChange(checked) : ''"
                        :title="filter.name"/>
        <img v-if="filter.type == 'select-arrow'"
             class="filter-arrow"
             src="@/assets/images/mypage-filter-sub-select-arrow.png"
             alt=""/>
      </template>
    </div>
    <div class="search-wrap">
      <div v-if="!this.filterData.searchInput?.isHidden"
           class="search-input">
        <input ref="keyword"
               :value="this.filterData.searchInput?.value"
               type="text"
               :placeholder="searchInputPlaceholder"
               @keyup.enter="this.onSubmitKeyword"
               @input="onChangeKeyword"/>
        <img src="@/assets/images/mypage-search.png"
             alt=""
             @click="this.onSubmitKeyword"/>
      </div>
      <template v-if="this.filterData.createBtnList && this.filterData.createBtnList.length > 0">
        <template v-for="(createBtn, index) in this.filterData.createBtnList" :key="index">
          <button v-if="createBtn.isVisible"
                  class="create-btn"
                  @click="createBtn.onCreate">
            {{ createBtn.name }}
          </button>
        </template>
      </template>

      <template v-if="this.createBtn?.isVisible ?? false">
        <template v-if="this.createBtn.isLoading ?? false">
          <button class="create-btn"
                  @click="this.createBtn.onClickLoading">
            <LoadingBox
                variant="white"
                size="14px"
                :padding="this.createBtn.loadingPadding"
            />
          </button>
        </template>
        <template v-else>
          <button class="create-btn"
                  @click="this.createBtn.onCreate"
          >{{ this.createBtn.name }}</button>
        </template>
      </template>
    </div>
    <slot name="innerLayout"></slot>
  </div>
</template>

<script>
import CustomCheckbox from './CustomCheckbox.vue';
import CustomSelect from './CustomSelect.vue';
import LoadingBox from "@/components/LoadingBox.vue";

export default {
  name: "Filter",
  props: {
    filterData: Object,
  },
  data() {
    return {
      keyword: null,
    }
  },
  methods: {
    onSubmitKeyword() {
      this.filterData.searchInput?.onChange(this.$refs.keyword.value)
    },
    onChangeKeyword(event) {
      if (this.filterData.searchInput && this.filterData.searchInput.onRealtimeChange) {
        this.filterData.searchInput.onRealtimeChange(event.target.value)
      }
    },
  },
  computed: {
    createBtn() {
      return this.filterData.createBtn;
    },
    searchInputPlaceholder() {
      const placeholder = this.filterData.searchInput?.placeholder;
      if (placeholder && placeholder.length > 0) {
        return placeholder;
      }
      return "검색";
    },
  },
  components: {LoadingBox, CustomSelect, CustomCheckbox }
}
</script>
