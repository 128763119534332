<template>
  <div :class="['popup', { hide: !isVisible }]">
    <div class="popup-shadow" @click="this.onClose"></div>
    <div :class="['popup-body']">
      <div class="popup-title">
        학습지 다운로드

        <button class="close-btn" @click="this.onClose">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 20 20" data-svg-name="close" class="css-u5k74v" data-element="SVGIcon" data-component="Icon" data-source-file="Icon.tsx" style="width: 24px; color: rgb(112, 112, 112);"><path fill="silver" d="M15.833 5.342l-1.175-1.175L10 8.825 5.342 4.167 4.167 5.342 8.825 10l-4.658 4.658 1.175 1.175L10 11.175l4.658 4.658 1.175-1.175L11.175 10l4.658-4.658z"></path></svg>
        </button>
      </div>

      <div class="body-contents">
        <div class="body-section-title">분류 선택</div>

        <div class="body-tr">
          <div class="item-cb">
            <input
                type="checkbox"
                v-model="this.isCheckedExamPaper"
                id="examPaper"
            >
            <label for="examPaper">문제지</label>
          </div>
          <div class="item-cb">
            <input
                type="checkbox"
                v-model="this.isCheckedAnswerExplanation"
                id="answerExplanation"
            >
            <label for="answerExplanation">정답해설</label>
          </div>
        </div>

        <div class="body-section-title">다운 형식</div>

        <div class="body-tr">
          <div class="item-radio">
            <input
                type="radio"
                v-model="this.downloadType"
                id="downloadTypeMerge"
                :value="DownloadType.merge"
                name="downloadType"
            >
            <label for="downloadTypeMerge">
              <div class="radio-icon"></div>
              <span class="radio-text">하나의 PDF로 받기</span>
            </label>
          </div>
          <div class="item-radio">
            <input
                type="radio"
                v-model="this.downloadType"
                id="downloadTypeIndividual"
                :value="DownloadType.individual"
                name="downloadType"
            >
            <label for="downloadTypeIndividual">
              <div class="radio-icon"></div>
              <span class="radio-text">개별 PDF로 받기</span>
            </label>
          </div>
        </div>
      </div>

      <div class="btn-list">
          <button @click="onSubmit">다운로드</button>
      </div>
    </div>
  </div>
</template>
  
<script>
import utils from "@/utils";
import {DownloadType} from "@/utils/enums";

export default {
  props: {
    isVisible: Boolean,
    onClose: Function,
    worksheet: Object,
    workbook: Object,
  },
  watch: {
    isVisible() {
      this.isCheckedExamPaper = true;
      this.isCheckedAnswerExplanation = true;
    },
  },
  data() {
    return {
      isCheckedExamPaper: true, // 문제지
      isCheckedAnswerExplanation: true, // 정답해설
      downloadType: utils.DownloadType.merge,
    };
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      const worksheet = this.worksheet;
      const workbook = this.workbook;

      let pdfFile;
      let pdfProblemFile;
      let pdfSolutionFile;

      let fileName = '';
      let splitCharacter = ' - ';

      if (worksheet) {
        pdfFile = worksheet.pdf_file;
        pdfProblemFile = worksheet.pdf_problem_file;
        pdfSolutionFile = worksheet.pdf_solution_file;
        fileName = worksheet.name.replace(/(?:\r\n|\r|\n)/g, splitCharacter);
      } else if (workbook) {
        pdfFile = workbook.pdf_file;
        pdfProblemFile = workbook.pdf_problem_file;
        pdfSolutionFile = workbook.pdf_solution_file;
        fileName = workbook.name.replace(/(?:\r\n|\r|\n)/g, splitCharacter);
      } else {
        this.$toast.error('대상을 확인할 수 없습니다');
        return;
      }

      if (!pdfFile || !pdfProblemFile || !pdfSolutionFile) {
        this.$toast.error(`다운로드 파일을 찾을 수 없습니다\n해당 ${this.title}를 수정하거나 새로 생성하여 다시 시도해주세요`);
        return;
      }

      let pdfFilePath = utils.getServerMediaPath(pdfFile);
      let pdfProblemFilePath = utils.getServerMediaPath(pdfProblemFile);
      let pdfSolutionFilePath = utils.getServerMediaPath(pdfSolutionFile);

      if (this.downloadType == DownloadType.merge) {
        if (this.isCheckedExamPaper && this.isCheckedAnswerExplanation) {
          utils.downloadUrl(pdfFilePath, fileName);
        } else if (this.isCheckedExamPaper) {
          utils.downloadUrl(pdfProblemFilePath, `${fileName}${splitCharacter}문제지`);
        } else if (this.isCheckedAnswerExplanation) {
          utils.downloadUrl(pdfSolutionFilePath, `${fileName}${splitCharacter}정답해설`);
        }
      } else if (this.downloadType == DownloadType.individual) {
        if (this.isCheckedExamPaper) {
          utils.downloadUrl(pdfProblemFilePath, `${fileName}${splitCharacter}문제지`);
        }
        if (this.isCheckedAnswerExplanation) {
          utils.downloadUrl(pdfSolutionFilePath, `${fileName}${splitCharacter}정답해설`);
        }
      } else {
        this.$toast.error('다운로드 유형을 확인할 수 없습니다');
      }
    },
  },
  computed: {
    DownloadType() {
      return DownloadType;
    },
    title() {
      if (this.worksheet) {
        return '학습지';
      } else if (this.workbook) {
        return '교재';
      } else {
        return '';
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99;
  &.hide {
    display: none;
  }
  .popup-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: #000000, $alpha: 0.2);
  }
  .popup-body {
    position: relative;
    max-width: 450px;
    width: 100%;
    max-height: 100%;
    margin: auto;
    box-shadow: 0 3px 30px 0 rgba(0, 0, 0, 0.16);
    border: solid 1px #ccc;
    background-color: #fff;
    align-items: center;
    display: flex;
    flex-direction: column;
    color: #231f20;
    box-sizing: border-box;
    .popup-title {
      width: 100%;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.01em;
      font-weight: 500;
      display: flex;
      height: 72px;
      align-items: center;
      padding: 0 24px;
      border-bottom: 1px solid rgb(224, 224, 224);
      .close-btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin-left: auto;
        svg {
          width: 100%;
          color: rgb(112, 112, 112);
          path {
            fill: currentcolor;
          }
        }
      }
    }
    .body-contents {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      gap: 24px 0;
      border-bottom: 1px solid rgb(224, 224, 224);
      padding-bottom: 24px;
      overflow-y: auto;
      .body-section-title {
        width: 100%;
        height: 32px;
        font-size: 12px;
        line-height: 18px;
        background-color: rgb(250, 250, 250);
        border-top: 1px solid rgb(232, 232, 232);
        color: rgb(112, 112, 112);
        text-align: start;
        align-items: center;
        display: flex;
        padding: 0 24px;
      }
      .body-tr {
        width: 100%;
        display: flex;
        gap: 15px;
        padding: 0 24px;
        .item-cb {
          display: flex;
          input {
            display: none;
          }
          input + label {
            background: #FFFFFF;
            border: 1px solid #999999;
            border-radius: 20px;
            transition: all .2s ease-in-out;
            cursor: pointer;
            padding: 10px 15px;
            font-size: 14px;
            line-height: 1;
            color: #666666;
            font-weight: 500;
          }
          input:checked + label {
            background: rgba(0, 142, 213, 0.1);
            border-color: #008ED5;
            color: #008ED5;
            font-weight: 700;
          }
        }
        .item-radio {
          display: flex;
          input {
            display: none;
          }
          input + label {
            cursor: pointer;
            display: flex;
            gap: 5px;
            align-items: center;
            .radio-icon {
              width: 15px;
              height: 15px;
              transition: all .2s ease-in-out;
              border-radius: 50%;
              background: #FFFFFF;
              border: 1px solid #999999;
              position: relative;
              box-sizing: content-box;
              &:after {
                transition: all .2s ease-in-out;
                position: absolute;
                content: '';
                width: 11px;
                height: 11px;
                background: #FFFFFF;
                border-radius: 50%;
                top: 2px;
                left: 2px;
                box-sizing: content-box;
              }
            }
            .radio-text {
              transition: all .2s ease-in-out;
              font-size: 14px;
              color: #666666;
              line-height: 1;
            }
          }
          input:checked + label {
            .radio-icon {
              border-color: #008ED5;
              &:after {
                background: #008ED5;
              }
            }
          }
        }
      }
    }
    .btn-list {
        width: 100%;
        display: flex;
        gap: 8px;
        justify-content: flex-end;
        align-items: center;
        padding: 24px;
        &>button {
          align-items: center;
          justify-content: center;
          display: flex;
          cursor: pointer;
          width: 150px;
          height: 40px;
          border-radius: 4px;
          transition: opacity 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
          background-color: #008ED5;
          color: white;
          font-weight: 500;
          line-height: 1;
        }
    }
  }
}
</style>
